export const MenuItems = [

    {
        title: 'HOME',
        url: '#home',
        cName: 'nav-links'
    },
    {
        title: 'PRODUCT',
        url: '#products',
        cName: 'nav-links'
    },
    {
        title: 'SOFTWARE',
        url: '#footer',
        cName: 'nav-links'
    },
    {
        title: 'CONTACT',
        url: '#footer',
        cName: 'nav-links'
    },
    {
        title: 'SIGN UP',
        url: '#waitlist',
        cName: 'nav-links'
    },

]