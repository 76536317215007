import React from 'react';
import "./PayCard.css";
import saiPhoto from '../../assets/sai_poductphoto.jpg';
import saiossoft from '../../assets/saiossoft_logo.png'



const PayCard = () => {
  return (
    <paycard>
        
        <div className="container_product_card">
            <div></div>
            <div className="product_card">
                <div className="top_card">
                    {/* image & price */}
                    <img src={saiPhoto} className="product_image" alt="" />
                </div>
                <div className="bottom_card">
                    {/* name, decription, cta */}
                    <div className="product_info">
                        <h3>SAI v2.5</h3>
                        <h6>Linux Based Volumetric Computer</h6>
                        </div>
                        <div className="order_box">
                            <div>
                                <h4>$2,499</h4>
                                <h6>+ Shipping</h6>
                            </div>
                            <a href="https://buy.stripe.com/28o2cc9762QAcMM4gg">
                            <button type='button' className="cta_place_order">b u y</button>
                            </a>
                    </div>
                    
                </div>
            </div>
            <div>
                {/* <h1>Features</h1>
                <ul>
                    <li className="package_information">Ships with Sai Alpha 24</li>
                    <li className="package_information">Browser based app development enviorment</li>
                    <li className="package_information">Access to the SAIOS Cloud API</li>
                    <li className="package_information">Get Full access to our lastest software</li>
                    <li className="package_information">Your Sai will keep up to data automatically</li>
                </ul> */}
            </div>
            <div></div>
            <div className="product_card">
                <div className="top_card">
                    {/* image & price */}
                    <img src={saiossoft} className="product_image" alt="" />
                </div>
                <div className="bottom_card">
                    {/* name, decription, cta */}
                    <div className="product_info">
                        <h3>SAI-OS</h3>
                        <h6>Complete Software License</h6>
                        </div>
                        <div className="order_box">
                            <div>
                                <h4>$36.99</h4>
                                <h6>tri-monthly</h6>
                            </div>
                            <a href="https://buy.stripe.com/4gwdUU1EE9eYbIIcMN">
                            <button type='button' className="cta_place_order">b u y</button>
                            </a>
                    </div>
                </div>
            </div>
            <div>
                {/* <h1>Features</h1>
                <ul>
                    <li className="package_information">Vision Control</li>
                    <li className="package_information">Browser based app development enviorment</li>
                    <li className="package_information">Access to the SAIOS Cloud API</li>
                    <li className="package_information">Get Full access to our lastest software</li>
                    <li className="package_information">Your Sai will keep up to data automatically</li>
                </ul> */}
            </div>
        </div>  
    </paycard>    
  )
}


export default PayCard;

